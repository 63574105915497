<template>
  <date-range-picker :date-range="localDate"
                     no-compare
                     single-date-picker
                     :ranges="{}"
                     :min-date="minDate"
                     :max-date="maxDate"
                     :opens="opens"
                     :show-clear-button="!hideClearButton"
                     :time-picker="time"
                     :disabled="disabled"
                     @apply="$emit('apply')"
                     @update:dateRange="localDate = $event" />
</template>

<script>
import DateRangePicker from './DateRangePicker.vue'

export default {
  name: 'singleDatePicker',
  components: { DateRangePicker },
  props: {
    value: {
      type: String
    },
    time: {
      type: Boolean,
      default: false
    },
    minDate: {
      type: [String, Date],
      default () {
        return new Date()
      }
    },
    maxDate: {
      type: [String, Date],
      default () {
        return null
      }
    },
    opens: {
      type: String,
      default: 'center'
    },
    hideClearButton: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localDate: {
      get () {
        return {
          start: this.value2date(this.value)
        }
      },
      set ({ start }) {
        this.$emit('input', this.date2value(start))
      }
    },
    dbNull () {
      return this.time ? '1999-09-09T09:09:09' : '1999-09-09'
    }
  },
  methods: {
    value2date (value) {
      return value && value !== this.dbNull ? this.$moment(value).toDate() : null
    },
    date2value (date) {
      if (date) {
        const d = Array.isArray(date) ? date[0] : date
        const format = this.time ? 'YYYY-MM-DDTHH:mm:ss' : 'YYYY-MM-DD'
        return this.$moment(d).format(format)
      }
      return this.dbNull
    }
  }
}
</script>
